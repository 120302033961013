export const validationFormMixin = {
  data () {
    return {
      currentYear: new Date().getFullYear(),
      rules: {
        required: value => !!value || 'Requerido',
        counter: value => value.length <= 20 || 'Max 20 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.:\s@"]+(\.[^<>()[\]\\.:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-mail inválido'
        },
        passMinLength: (first) => (first && first.length >= 5) || 'Mínimo 5 caracteres',
        profileImgSize: (img) => !img || img.size < 2000000 || 'La foto debe pesar menos de 2 MB!',
      }
    }
  },
  methods: {
    objectHasNulls(object) {
      return Object.values(object).some(value => (value === null))
    },
  }
}