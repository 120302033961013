<template>
  <v-btn
    v-show="showBtn"
    ref="backToTopBtn"
    color="primary"
    fab
    title="Regresar hacia arriba"
    fixed
    bottom
    large
    right
    @click="backToTop"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      btn: null,
      showBtn: false
    }
  },
  computed: {
    scrollContainer() {
      return document.documentElement || document.body
    }
  },
  mounted() {
    document.addEventListener('scroll', () => {
      if (this.scrollContainer.scrollTop > 100) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
    })
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    document.removeEventListener('scroll', () => {
      if (this.scrollContainer.scrollTop > 100) {
        this.showBtn = true
      } else {
        this.showBtn = false
      }
    })
  },
  methods: {
    backToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style>

</style>